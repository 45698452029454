h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.4286rem;
    color: #2ea39e;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 3rem;
    letter-spacing: 0.2em;
}
h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.1rem;
    color: #4f4f50;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

.sv_main .sv_container {
    color: #4f4f50;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
}

.sv_main,
.sv_main .sv_custom_header {
    background-color: transparent;
}

.sv_main .sv_qstn {
    overflow: inherit;
}

.sv_main input[type="button"] {
    background-color: #ef6d53;
    letter-spacing: 0.28px;
    font-weight: 600;
    font-size: 0.9rem;
    letter-spacing: 1px;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    min-width: 112px;
    min-height: 3.2143rem;
    border-width: 3px;
    border-radius: 0;
}

.sv_main input[type="button"]:hover, .sv_main button:hover {
    background-color: #ef6d53;
}

.sv_main .card-footer {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
}

.sv_main .panel-footer {
    text-align: right;
}

.sv_main .circle {
    margin-right: 0.2em;
}

.sv_main .radio {
    margin-left: 1.25rem;
    margin-top: 0.5rem;
}
