#modal {
  width: 300px;
  height: 200px;
  line-height: 200px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -150px;
  background-color: whitesmoke;
  border-radius: 5px;
  text-align: center;
  z-index: 11; /* 1px higher than the overlay layer */
}

.presentii-toast {
  background-color: #2ea39e;
  border-radius: 0;
  box-shadow: none;
  border: 0;
  position: fixed;
  z-index: 1000;
}

.presentii-toast .toast-header {
  padding: 0.5rem 1rem;
  color: white;
  background-color: rgba(255, 255, 255, 0.05);
}

.presentii-toast .toast-header .close {
  color: white;
  text-shadow: none;
}

.presentii-toast .toast-body {
  padding: 1.5rem 1.25rem;
  color: white;
}

.sliderCommentText {
  background-color: #edeeee;
  color: #333;
  font-size: 0.85rem;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  border: solid 1px #ddd;
  width: 100%;
}

@media screen and (min-width: 992px) {
  .presentii-toast {
    width: 350px;
    bottom: 80px;
    right: 40px;
  }
}

@media screen and (min-width: 0px) and (max-width: 992px) {
  .presentii-toast {
    height: 120px;
    bottom: 20px;
    right: 20px;
    left: 20px;
  }
}
